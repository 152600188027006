<template>
  <Button v-bind="$attrs" @click="showHubspotModal = true">
    <slot />
  </Button>
</template>

<script lang="ts" setup>
import Button from '@cypress-design/vue-button'
import { showHubspotModal } from '@/stores'
</script>
